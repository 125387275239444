import React, {JSX, useEffect, useState} from 'react';
import TeamScreen from "./screen/TeamScreen";
import {NotesScreen} from "./screen/CalendarScreen";
import AgregatorScreen from "./screen/AgregatorScreen";
import {CalendarScreen} from "./screen/CalendarScreen";
import {Api} from "./Api";
import {HelloScreen} from "./screen/HelloScreen";
import { SashaGptScreen } from './screen/SettingsScreen';
import { useLazyEffect } from './Effects';

type Menus = "О нас" | "Календарь" | "SashaGPT" | "Заметки" | "Агрегатор"

function App(props : {api : Api}) : JSX.Element {
    let acceptedLicence = useLazyEffect(props.api.isUserAuthorised, 0)
    let [windiw, setWindow] = useState<Menus | "Авторизация">(acceptedLicence == true ? "SashaGPT": "Авторизация");
    if (acceptedLicence == true && windiw == "Авторизация") {
        windiw = "SashaGPT"
    }
    // @ts-ignore
    document.getElementById("bodyDiv").style.backgroundColor = windiw === "О нас" ? "var(--primary1)" : "#FFFFFF";
    return <div style={{
        height: "100%",
        width: "100%",
        display: acceptedLicence != null ? "flex" : "none",
        backgroundColor:  windiw === "О нас" ? "var(--primary1)" : "#FFFFFF",
    }}>
        <div style={{ display: windiw != "Авторизация" ? "none" : "flex" }}>
            <HelloScreen acceptAll={() => {
                props.api.userAgreed();
                setWindow("SashaGPT")
            }}/>
        </div>
        { windiw != "Авторизация" && 
            <div style={{
                height: "100%",
                width: "100%",
                marginBottom: "49px",
                display: "flex",
                alignItems: "center",
                alignContent: "center",
                flexDirection: "column"
            }}>
            <Windows api={props.api} menu={windiw}></Windows>
            <BottomMenu menu={windiw} setWidndos={setWindow}></BottomMenu>
            </div>
        }
    </div>
}


function Windows(props : {menu : Menus, api : Api}) {
    let [version, setVersion] = useState(0)
    let incrementVersion = () => setVersion(version + 1)
    useEffect(() =>{
        const timer = setTimeout(() => setVersion(version + 1), 5000);
        return () => clearTimeout(timer);
    }, [version])

    return <div style={{
        overflowY: "scroll",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        alignItems: "center",
        alignContent: "center",
        borderColor: "transparent"
    }}>
        <div style={{display: props.menu !== "О нас"     ? "none" : "flex", width: "100%", flexDirection: "column", alignItems: "center", alignContent: "center"}}><TeamScreen/></div>
        <div style={{display: props.menu !== "Календарь" ? "none" : "flex", width: "100%", flexDirection: "column", alignItems: "center", alignContent: "center"}}><CalendarScreen api={props.api} version={version} updateVersion={incrementVersion}/></div>
        <div style={{display: props.menu !== "SashaGPT"  ? "none" : "flex", width: "100%", flexDirection: "column", alignItems: "center", alignContent: "center"}}>
            <SashaGptScreen api={props.api} version={version} incrementVersion={incrementVersion}/>
        </div>
        <div style={{display: props.menu !== "Заметки"   ? "none" : "flex", width: "100%", flexDirection: "column", alignItems: "center", alignContent: "center"}}><NotesScreen api={props.api} version={version} updateVersion={incrementVersion}/></div>
        <div style={{display: props.menu !== "Агрегатор" ? "none" : "flex", width: "100%", flexDirection: "column", alignItems: "center", alignContent: "center"}}><AgregatorScreen api={props.api} /></div>
    </div>;
}

function BottomMenu(props : {menu : Menus, setWidndos : (w : Menus) => void}) {
    let menu = props.menu;
    let setWindow = props.setWidndos
    const blueness = (a : boolean) => a ? "blue." : ""
    return  <div style={{
        display: "flex",
        flexDirection: "row",
        position: "fixed",
        height: "49px",
        bottom: "0px",
        left: "0px",
        backgroundColor: "#FFFFFF",
        borderColor: "transparent",
        width: "100%"
    }}>
        <BottomMenuOption
            name={"О нас"}
            icon={`assets/${blueness(menu === "О нас")}person.crop.circle.fill.svg`}
            active={menu === "О нас"}
            onClick={() => setWindow("О нас")}/>
        <BottomMenuOption
            name={"Календарь"}
            icon={`assets/${blueness(menu === "Календарь")}calendar.svg`}
            active={menu === "Календарь"}
            onClick={() => setWindow("Календарь")}/>
        <BottomMenuOption
            name={"SashaGPT"}
            icon={`assets/${blueness(menu === "SashaGPT")}cool_s.svg`}
            active={menu === "SashaGPT"}
            onClick={() => setWindow("SashaGPT")}/>
        <BottomMenuOption
            name={"Заметки"}
            icon={`assets/${blueness(menu === "Заметки")}pencil.circle.fill.svg`}
            active={menu === "Заметки"}
            onClick={() => setWindow("Заметки")}/>
        <BottomMenuOption
            name={"Агрегатор"}
            icon={`assets/${blueness(menu === "Агрегатор")}book.fill.svg`}
            active={menu === "Агрегатор"}
            onClick={() => setWindow("Агрегатор")}/>
    </div>;
}


function BottomMenuOption(props : {name : Menus, icon : string, active : boolean, onClick : () => void}) {
    return <button style={{
        width: "25%",
        height: "49px",
        display: "flex",
        flexDirection: "column",
        gap: "2px",
        borderColor: "transparent",
        backgroundColor: "transparent",
        alignContent: "center",
        justifyContent: "center",
        alignItems: "center",
        opacity: props.active ? 1.0 : 0.4
    }} onClick={() => props.onClick()}>
        <img src={props.icon} alt={props.name + " icon"} style={{ width: "22px", height: "22px" }}/>
        <span style={{
            fontFamily: "Inter",
            fontSize: "10px",
            fontWeight: "510",
            lineHeight: "12px",
            textAlign: "center",
            color: props.active ? "var(--accent2)" : "var(--primary6)"
        }}>{props.name}</span>
    </button>
}

export default App;
