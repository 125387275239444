import {Api, EventBody, NoteBody, Preset, SashaEvent} from "../Api";
import {useState} from "react";
import Popup from 'reactjs-popup';
import { useLazyEffect } from "../Effects";

function TitleInput(props : {value : string, setValue : (newValue : string) => void}) {
    return <textarea style={{
        borderColor: "transparent",
        backgroundColor: "transparent",
        textAlign: "left",
        color: "var(--primary5)",
        display: "flex",
        width: "100%",
        borderWidth: "0",
        padding: "0",
        outline: "transparent",
        resize: "none",
        height: "250px"
    }} className={"bodyXLBold"}  value={props.value} onChange={(e) => props.setValue(e.target.value)} placeholder={"Например, Запланировать звонок сегодня в 8 вечера"} rows={2}>

    </textarea>;
}

export function EdipPromtPopup(props : { api : Api, disabled : boolean, close : () => void, version : number, incrementVersion : () => void }) {
    let current = useLazyEffect(props.api.promt, props.version) ?? { text: "Please wait", mode: { title: "please wait", id: "please wait", description: "please wait", metadata_:"" }, id: "please wait" }

    return <Popup modal={true} nested={true} open={!props.disabled} position={"left center"} offsetY={-49}>
        <div style={{ height: "100vh", display: "flex", flexDirection: "column", width: "100%" }}>
            <div style={{height: "100%"}} onClick={() => props.close()}>
            </div>
            <CreateNoteScreenBody api={props.api} close={props.close} current={current}/>
        </div>
    </Popup>
}

function CreateNoteScreenBody(props : { api : Api, close : () => void, current : Preset }) {
    let [text, setText] = useState(props.current.text)
    return <div style={{
        width: "100%",
        minWidth: "350px",
        height: "400px",
        borderRadius: "12px 12px 0px 0px",
        border: "0.33px 0px 0px 0px",
        gap: "20px",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#FFFFFF",
        boxShadow: "0px 4px 32px 0px #23232852",
        paddingTop: "16px"
    }}>
        <div style={{
            width: "100%",
            height: "100%",
            gap: "8px",
            padding: "8px 20px 0px 20px",
            boxSizing: "border-box"
        }}>
            <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                <TitleInput value={text} setValue={setText} />
                <SendButton api={props.api} prompt={{ text: text, mode: props.current.mode, id: props.current.id }} close={props.close}/>
            </div>
        </div>
    </div>
}

function SendButton(props : { api : Api, prompt : Preset, close : () => void }) {
    return <img src={"assets/Button.svg"} onClick={() => {
        props.api.updatePromt(props.prompt)
        props.close()
    }}/>;
}