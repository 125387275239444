import {Api, EventBody, NoteBody, SashaEvent} from "../Api";
import {useState} from "react";
import Popup from 'reactjs-popup';
import { fixTimezone, toLocalISOString } from "../time";

function TitleInput(props : {value : string, setValue : (newValue : string) => void}) {
    return <textarea style={{
        borderColor: "transparent",
        backgroundColor: "transparent",
        textAlign: "left",
        color: "var(--primary5)",
        display: "flex",
        width: "100%",
        borderWidth: "0",
        padding: "0",
        outline: "transparent",
        resize: "none"
    }} className={"bodyXLBold"}  value={props.value} onChange={(e) => props.setValue(e.target.value)} placeholder={"Например, Запланировать звонок сегодня в 8 вечера"} rows={2}>

    </textarea>;
}

function DescriptionInput(props : {value : string, setValue : (newValue : string) => void}) {
    return  <textarea style={{
        borderColor: "transparent",
        backgroundColor: "transparent",
        textAlign: "left",
        color: "var(--primary5)",
        width: "100%",
        borderWidth: "0",
        padding: "0",
        outline: "transparent",
        resize: "none"
    }} className={"bodyM"} value={props.value} onChange={(e) => props.setValue(e.target.value)} placeholder={"Описание задачи"} rows={6}>

    </textarea>;
}

export function CreateNotePopup(props : { api : Api, id : string | null, body : NoteBody | EventBody, disabled : boolean, close : () => void }) {
    return <Popup modal={true} nested={true} open={!props.disabled} position={"left center"} offsetY={-49}>
        <div style={{ height: "100vh", display: "flex", flexDirection: "column", width: "100%" }}>
            <div style={{height: "100%"}} onClick={() => props.close()}>
            </div>
            <CreateNoteScreenBody api={props.api} id={props.id} close={props.close} body={props.body}/>
        </div>
    </Popup>
}


function CreateNoteScreenBody(props : { api : Api, id : string | null, close : () => void, body : NoteBody | EventBody }) {
    let [title, setTitle] = useState(props.body?.title ?? '')
    let [text, setText] = useState(props.body?.text ?? '')
    let defaultTime : string | undefined = ("time" in props.body) ? toLocalISOString(props.body.time) : undefined;
    let [date, setDate] = useState(defaultTime)
    return <div style={{
        width: "100%",
        minWidth: "350px",
        height: "400px",
        borderRadius: "12px 12px 0px 0px",
        border: "0.33px 0px 0px 0px",
        gap: "20px",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#FFFFFF",
        boxShadow: "0px 4px 32px 0px #23232852",
        paddingTop: "16px"
    }}>
        <div style={{
            width: "100%",
            height: "100%",
            gap: "8px",
            padding: "8px 20px 0px 20px",
            boxSizing: "border-box"
        }}>
            <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                <TitleInput value={title} setValue={setTitle} />
                <SendButton api={props.api} id={props.id} title={title} text={text} close={props.close} date={date}/>
            </div>
            <DescriptionInput value={text} setValue={setText}/>
        </div>
        <div style={{
            display: "flex",
            flexDirection: "row",
            gap: "8px",
            width: "100%",
            height: "52px"
        }}>
            <input type="datetime-local" style={{ display: (date === undefined) ? "none" : "flex" }} value={date} onChange={ (event) => setDate(event.target.value) }/>
            <div style={{margin: "auto"}}/>
            <DeleteButton api={props.api} id={props.id} body={props.body} close={props.close}></DeleteButton>
        </div>
    </div>
}

function DeleteButton(props: { api : Api, id : string | null, body : EventBody | NoteBody, close : () => void}) {
    return <img src={"assets/trash.fill.svg"} style={{ display: props.id === null ? "none" : "flex" }} onClick={() => {
        if (props.id != null) {
            if ("time" in props.body) {
                props.api.deleteEvent(props.id);
            } else {
                props.api.deleteNote(props.id);
            }
        }
        props.close()
    }}/>
}

function SendButton(props : { api : Api, id : string | null, title : string, text : string, date : string | undefined, close : () => void }) {
    return <img src={"assets/Button.svg"} onClick={() => {
        if (props.id != null) {
            if (props.date === undefined) {
                props.api.updateNote(props.id, { title: props.title, text: props.text })
            } else {
                props.api.updateEvent(props.id, { title: props.title, text: props.text, time: new Date(Date.parse(props.date)) })
            }
        } else {
            if (props.date === undefined) {
                props.api.createNote({ title: props.title, text: props.text })
            } else {
                props.api.createEvent({ title: props.title, text: props.text, time: new Date(Date.parse(props.date)) })
            }
        }
        props.close()
    }}/>;
}