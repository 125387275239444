import React from 'react';
import '../App.css';
import Bullet from "../components/Bullet";

function TeamScreen() {
    return (
        <div style={{
            backgroundColor: "var(--primary1)",
            width: "100%",
            display: "flex",
            alignItems: "center",
            alignContent: "center",
            flexDirection: "column"
        }}>
            <div style={{
                display: "flex",
                flexDirection: "column",
                gap: "24px",
                padding: "16px",
                width: "358px",
            }}>
                <Heading></Heading>
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                }}>
                    <Description></Description>
                    <OrderWork></OrderWork>
                </div>
                <PersonDescription
                    name={"Герасимюк Максим"}
                    description={"Спикер, маркетолог и автор сетки видеоблогов с общим охватом более 1 000 000 подписчиков, ex-маркетолог Prodamus, блогер-партнёр VK GROUP и Газпром-Медиа"}
                    role={"CEO"}
                    url={"assets/ГерасимюкМаксимАватарка.jpg"}
                ></PersonDescription>
                <PersonDescription
                    name={"Сергей Романов"}
                    description={"Серийный предприниматель, основатель одной из крупнейших в России юридических компании Единый Центр Защиты с офисами в 54+ регионах РФ, а также ряда других крупных проектов"}
                    role={"CEO"}
                    url={"assets/СергейРомановАватарка.jpg"}
                ></PersonDescription>
            </div>
        </div>
    );
}

function Heading() {
    return (<div className={"heading4"} style={{ color: "var(--primary6)", textAlign: "left" }}>
        Наша команда
    </div>);
}

function Description() {
    return (<div style={{
        display: "flex",
        flexDirection: "column",
        gap: "8px",
        padding: "24px",
        backgroundColor: "var(--primary0)",
        color: "var(--primary6)",
        borderRadius: "12px",
        borderColor: "transparent",
        textAlign: "left",
        justifyContent: "center",
    }} className={"bodyM"}>
        Компания Т2 — мы создаём приложения для бизнеса и разрабатываем собственные. Наше компания на рынке с 2011 года и обладает безупречной репутацией.<br></br><br></br>
        Наш проект Sasha входит в ТОП-100 проектов Академии Инноваций при правительстве Москвы, а мы резиденты инновационного кампуса МГУ.
        <div style={{
            display: "flex",
            flexDirection: "column",
            gap: "4px",
        }}>
            <span className={"bodyMBold"} style={{ color: "var(--primary6)" }}>Наши услуги</span>
            — Разработка приложений для бизнеса;<br></br>
            — Обучение и консультативная поддержка;<br></br>
            — Помощь стартапам и продвижение проектов.<br></br>
        </div>
    </div>);
}

function OrderWork() {
    return (
	<a href="https://t.me/Pravomaxi">
	<div style={{
		display: "flex",
		flexDirection: "column",
		gap: "24px",
		height: "50px",
		backgroundColor: "var(--accept1)",
		color: "var(--primary0)",
		borderRadius: "12px",
		borderColor: "transparent"
	}} className={"bodyLBold center_items"}>
	Заказать разработку
	</div>
	</a>
	);
}

function PersonDescription(props: { name: string, description: string, role : string, url : string }) {
    return (<div style={{
        display: "flex",
        flexDirection: "column",
        gap: "12px",
        padding: "24px",
        backgroundColor: "var(--primary0)",
        color: "var(--primary6)",
        borderRadius: "16px",
        borderColor: "transparent",
        textAlign: "left",
        justifyContent: "center",
    }} className={"bodyM center_items"}>
        <img style={{
            width: "128px",
            height: "128px",
            borderRadius: "64px"
        }} src={props.url}  alt={"avatar"}/>
        <div style={{
            display: "flex",
            flexDirection: "column",
            gap: "8px",
        }} className={"center_items"}>
            <span className={"heading5"}>{props.name}</span>
            <Bullet text={props.role} color={"var(--primary1)"} backgroundColor={"var(--accent1)"} textStyle={"bodyLBold"} padding={"6px 16px 6px 16px"}></Bullet>
        </div>
        {props.description}
    </div>);
}

export default TeamScreen;


