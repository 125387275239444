import React from "react";

export default function Bullet(props: {text : string, color : string, backgroundColor : string, textStyle : string, padding : string }) {
    return <div style={{
        display: "flex",
        padding: props.padding,
        borderRadius: "32px",
        gap: "10px",
        backgroundColor: props.backgroundColor,
        color: props.color
    }} className={"center_items " + props.textStyle}>{props.text}</div>;
}