import {useEffect, useState} from "react";
import {Api, SashaEvent} from "./Api";

export function useLazyEffect<T>(f : () => Promise<T>, version : number):  T | null {
    const [value, setValue] = useState<T | null>(null);
    useEffect(() => {
        const fetchData = async () => {
            let neww = await f()
            setValue(neww);
            console.log(version)
        }
        fetchData()
    }, [f, version]);
    return value;
}

export function useDate() {
    const [value, setValue] = useState<Date>(new Date());

    useEffect(() => {
        const interval = setInterval(() => setValue(new Date()), 5000);

        return () => clearInterval(interval);
    }, []);

    return value;
}

export function useEvents(api : Api, today : Date, version : number) {
    const [tasks, updateTasks] = useState<SashaEvent[] | null>(null);
    const day = today.getDate();
    const month = today.getMonth();
    const year = today.getFullYear();

        useEffect(() => {
            const fetch = async () => {
                let events = await api.events();
                updateTasks(events);
                console.log(version)
            }
            fetch();
        }, [api, day, month, year, version])
       /* let events =
            (await api.events())
                .filter((e) =>
                    e.time.getDay() === day
                    && e.time.getMonth() === month
                    && e.time.getFullYear() === year);*/

    
    return tasks;
}