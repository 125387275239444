function Logo() {
    return <div style={{height: "100%", width: "1px" }}><img src={"assets/SashaGPT.svg"} style={{
        position: "absolute",
        transform: "translate(-50%,-50%)",
        top: "50%",
        left: "50%",
    }} alt={"logo"}/></div>
}

function Bottom(props : { acceptAll : () => void }) {
    return <div style={{
        display: "flex",
        flexDirection: "column",
        gap: "14px",
        textAlign: "center",
        position: "absolute",
        bottom: "0px",
        padding: "16px"
    }}>
        <span className={"bodyS"} style={{ color: "var(--primary4)" }}>При нажатии на кнопку «Продолжить», Вы соглашаетесь с условиями <a href={""}>Пользовательского соглашения</a> и <a href={""}>Политики конфиденциальности</a></span>
        <div style={{
            borderRadius: "12px",
            borderColor: "transparent",
            width: "100%",
            height: "50px",
            color: "var(--primary0)",
            backgroundColor: "var(--accent2)",
            display: "flex",
            flexDirection: "column",
            alignContent: "center",
            alignItems: "center",
            justifyContent: "center"
        }} onClick={() => props.acceptAll()}>
            Продолжить
        </div>
    </div>;
}

export function HelloScreen(props : { acceptAll : () => void }) {
    return (<div style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column"
    }}>
        <Logo/>
        <Bottom acceptAll={props.acceptAll}/>
    </div>);
}
