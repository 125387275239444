import {AiBot, Api, EventBody, Mode, Note, NoteBody, Preset, SashaEvent, SearchResponse, Tag} from "./Api";


type ServerEvent = {
    title : string
    text : string
    timepoint_utc : string,
    id : string,
    owner_telegram_id : string
}

type ServerBot = {
    title : string,
    description : string,
    id : string
}

type ServerTag = {
    name : string,
    font_color : string,
    background_color: string,
    id : string
}

export class RestApi implements Api {
    link = "https://back.ddcllc.ru/";
    owner_telegram_id : string;
    token : string;

    constructor(owner_telegram_id : string, token : string) {
        this.owner_telegram_id = owner_telegram_id;
        this.token = token;
    }

    presets: () => Promise<Preset[]> = async () => {
        const result = await fetch(this.link + 'prompt_presets', {
            redirect: 'follow',
            headers: {
              'accept': 'application/json'
            }
        });
        if (!result.ok) {
            console.log(result);
            return [];
        } else {
            let res = (await result.json()) as {
                text : string,
                mode_id : string,
                id : string
            }[]

            let presets = []

            for (let i of res) {
                presets.push(
                    {
                        text: i.text,
                        id: i.id,
                        mode: await this.get_mod(i.mode_id)
                    }
                )
            }

            console.log(JSON.stringify(presets), 'PRESETS')
            return presets;
        }
    }

    get_mod = async (id : string) : Promise<Mode> => {
        const result = await fetch(this.link + 'mode/' + id, {
            redirect: 'follow',
            headers: {
              'accept': 'application/json'
            }
        });
        if (!result.ok) {
            console.log(result);
            return { title: "error", description: JSON.stringify(result), metadata_: "", id: "" };
        } else {
            return (await result.json()) as Mode;
        }
    }

    isUserAuthorised: () => Promise<boolean> = async () => {
        const result = await fetch(this.link + 'user/' + this.owner_telegram_id, {
            redirect: 'follow',
            headers: {
              'accept': 'application/json'
            }
        });
        if (!result.ok) {
            console.log(result);
            return false;
        } else {
            let betaRes =  ((await result.json()) as { approved_policy : boolean })
            return betaRes.approved_policy;
        }
    }

    userAgreed: () => Promise<void> = async () => {
        const result = await fetch(this.link + 'user/' + this.owner_telegram_id + "/approve", {
            method: "POST",
            redirect: 'follow',
            headers: {
              'accept': 'application/json'
            }
        });
        if (!result.ok) {
            console.log(result);
        }
    }

    agregator: () => Promise<AiBot[]> = async () => {
        const result = await fetch(this.link + 'services', {
            redirect: 'follow',
            headers: {
              'accept': 'application/json'
            }
        });
        if (!result.ok) {
            console.log(result);
            return [];
        } else {
            let res = []
            let betaRes =  ((await result.json()) as ServerBot[])
            for (let value of betaRes) {
                res.push(this.asClientBot(await this.tags(value.id))(value))
            }
            return res
        }
    }

    tags = async (service : string) => {
        const result = await fetch(this.link + 'tags?service_id=' + service, {
            redirect: 'follow',
            method: "GET",
            headers: {
              'accept': 'application/json',
            }
        });
        if (!result.ok) {
            console.log(result);
            return [];
        } else {
            return ((await result.json()) as ServerTag[]).map(this.asClientTag)
        }
    }

    asClientBot = (tags : Tag[]) => (params:ServerBot) : AiBot => {
        return { title: params.title, descriptopn: params.description, tags: tags }
    }

    asClientTag(value : ServerTag) : Tag {
        return {
            name: value.name, 
            color: value.font_color,
            backgroundColor: value.background_color 
           }
    }

    modes: () => Promise<Mode[]> = async () => {
        const result = await fetch(this.link + 'modes', {
            headers: {
              'accept': 'application/json'
            }
        });
        if (!result.ok) {
            console.log(result);
            return [];
        } else {
            return (await result.json()) as Mode[]
        }
    }
    
    promt: () => Promise<Preset> = async () => {
        const result = await fetch(`${this.link}prompts?owner_telegram_id=` + this.owner_telegram_id, {
            method: 'GET',
            redirect: 'follow',
            headers: {
                'accept': 'application/json'
            }
        });
        if (!result.ok) {
            console.log(result);
            return { text: "ERROR", id: "error", mode: { title: "ERROR", description: "ERROR", metadata_: "" } } as Preset;
        }
        let json = await result.json()
        console.log(JSON.stringify(json), 'PROMPT')
        if (json.length > 0) {
            let a = (json as Preset[])[json.length - 1]
            a.mode = await this.get_mod(json[json.length - 1].mode_id)
            console.log(JSON.stringify(a))
            return a
        } else {
            let out: Preset = {
                'text': '',
                'id': '',
                'mode': {
                    'title': '',
                    'description': '',
                    'metadata_': '',
                    'id': '566f7947-997e-49cf-a62a-a67580e32e57',
                }
            }
            return out;
        }
    }

    updatePromt: (newPromt: Preset) => Promise<void>= async (newPromt : Preset) => {
        console.log(JSON.stringify(newPromt))
        console.log(JSON.stringify({
            text: newPromt.text,
            mode: newPromt.mode.id,
            owner_telegram_id: this.owner_telegram_id
        }))
        await fetch(this.link + 'prompt?owner_telegram_id=' + this.owner_telegram_id, {
            method: 'POST',
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                text: newPromt.text,
                mode_id: newPromt.mode.id,
                owner_telegram_id: this.owner_telegram_id
            })
        });
    }


    createEvent = async (data: EventBody) => {
        console.log("Create event time: " + data.time.toISOString())
        const responce = await fetch(`${this.link}/events?owner_telegram_id=${this.owner_telegram_id}`, {
            method: 'POST',
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            body: JSON.stringify({
                'title': data.title,
                'text': data.text,
                'timepoint_utc': data.time.toISOString()
            }),
        });
        if (!responce.ok) {
            console.log(responce);
        }
    }

    createNote = async  (data: NoteBody) => {
        const responce = await fetch(`${this.link}/notes?owner_telegram_id=${this.owner_telegram_id}`, {
            method: 'POST',
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            body: JSON.stringify({
                'title': data.title,
                'text': data.text
            })
        });
        if (!responce.ok) {
            console.log(responce);
        }
    }

    deleteEvent = async (id: string) => {
        const responce = await fetch(`${this.link}/events/${id}`, {
            method: 'DELETE',
            redirect: 'follow',
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
        if (!responce.ok) {
            console.log(responce);
        }
    }

    deleteNote= async (id: string) => {
        const responce = await fetch(`${this.link}/notes/${id}`, {
            method: 'DELETE',
            redirect: 'follow',
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
        if (!responce.ok) {
            console.log(responce);
        }
    }

    event = async (id: string) : Promise<SashaEvent | null> => {
        const result = await fetch(`${this.link}events?owner_telegram_id=${this.owner_telegram_id}&event_id=${id}`, {
            method: 'GET',
            redirect: 'follow',
            headers: {
                'accept': 'application/json'
            }
        });
        if (!result.ok) {
            console.log(result);
            return null;
        }

        let json = await result.json()
        if (json satisfies ServerEvent) {
            return this.asForntendEvent(json as ServerEvent)
        } else {
            console.log("Invalid answer:");
            console.log(result);
            return null;
        }
    }

    events = async () : Promise<SashaEvent[]> => {
        const result = await fetch(`${this.link}/events?owner_telegram_id=${this.owner_telegram_id}`, {
            method: 'GET',
            redirect: 'follow',
            headers: {
                'accept': 'application/json'
            }
        });
        if (!result.ok) {
            console.log(result);
            return [];
        }
        let json = await result.json()
        if (json satisfies ServerEvent[]) {
            return (json as ServerEvent[]).map(this.asForntendEvent);
        } else {
            console.log("Invalid answer:");
            console.log(result);
            return [];
        }
    }

    note = async (id: string) : Promise<Note | null> => {
        const result = await fetch(`${this.link}/notes?owner_telegram_id=${this.owner_telegram_id}&event_id=${id}`, {
            method: 'GET',
            redirect: 'follow',
            headers: {
                'accept': 'application/json'
            }
        });
        if (!result.ok) {
            console.log(result);
            return null;
        }

        let json = await result.json()
        if (json satisfies Note) {
            return json as Note
        } else {
            console.log("Invalid answer:");
            console.log(result);
            return null;
        }
    }

    notes = async () : Promise<Note[]> => {
        const result = await fetch(`${this.link}/notes?owner_telegram_id=${this.owner_telegram_id}`, {
            method: 'GET',
            redirect: 'follow',
            headers: {
                'accept': 'application/json'
            }
        });
        if (!result.ok) {
            console.log(result);
            return [];
        }
        let json = await result.json()
        if (json satisfies Note[]) {
            return json as Note[]
        } else {
            console.log("Invalid answer:");
            console.log(result);
            return [];
        }
    }

    search = async (query : string) : Promise<SearchResponse> => {
        if (query === '') {
            return { events: [], notes: [] };
        }
        const result = await fetch(`${this.link}search?owner_telegram_id=${this.owner_telegram_id}&query=${query}`, {
            method: 'GET',
            redirect: 'follow',
            headers: {
                'accept': 'application/json'
            }
        });
        if (!result.ok) {
            console.log("Not ok search: " + JSON.stringify(result));
            return { events: [], notes: [] };
        }
        let json = await result.json()
        if (json satisfies { notes : Note[], events : ServerEvent[] }) {
            let asEvents = json as { notes : Note[], events : ServerEvent[] }
            return {
                notes: asEvents.notes,
                events: asEvents.events.map(this.asForntendEvent)
            }
        } else {
            console.log("Invalid answer:");
            console.log(result);
            return { events: [], notes: [] };
        }
    }


    asForntendEvent = (params: ServerEvent) : SashaEvent => {
        return {
            time: new Date(Date.parse(params.timepoint_utc)),
            text: params.text,
            title: params.title,
            id: params.id,
            owner_telegram_id: params.owner_telegram_id, 
        }
    }

    updateEvent = async (id : string, data : EventBody) : Promise<void> => {
        const responce = await fetch(`${this.link}/events/${id}`, {
            method: 'PUT',
            redirect: 'follow',
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                'title': data.title,
                'text': data.text,
                'timepoint_utc': data.time.toISOString()
            })
        });
        if (!responce.ok) {
            console.log(responce);
        }
    }

    updateNote = async (note_id: string, data: NoteBody): Promise<void> => {
        const responce = await fetch(`${this.link}/notes/${note_id}`, {
            method: 'PUT',
            redirect: 'follow',
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                'title': data.title,
                'text': data.text
            })
        });
        if (!responce.ok) {
            console.log(responce);
        }
    }
}