import React from "react";
import {SearchBox} from "../components/SearchBox";
import {AiBot, Api, Tag} from "../Api";
import { useLazyEffect } from "../Effects";


export default function AgregatorScreen(props : { api : Api }) {
    let variants = useLazyEffect(props.api.agregator, 0)
    return <SearchBox api={props.api}>
        {(active) =>
            (<div style={{
                padding: "16px",
                gap: "24px",
                display: active ? "flex" : "none",
                flexDirection: "column",
                width: "100%",
                boxSizing: "border-box",
            }}>
                <span className={"heading4"} style={{ color: "var(--primary6)", textAlign: "left" }}>Агрегатор</span>
                {variants != null && <Variants values={variants}/> }
                {variants == null && <div className="loader"/> }

            </div>)
        }
    </SearchBox>;
}

function Variants(props : { values : AiBot[] }) {
    let elements = props.values.map((value) => <Variant key={value.title} bot={value}/>)
    return <div style={{
        gap: "8px",
        display: "flex",
        flexDirection: "column"
    }}>{elements}</div>;
}

function Variant(props : { bot : AiBot }) {
    return <div style={{
        gap: "8px",
        display: "flex",
        flexDirection: "row",
        padding: "16px",
        backgroundColor: "var(--primary1)",
        width: "100%",
        boxSizing: "border-box",
        borderRadius: "12px",
        borderColor: "transparent"
    }}>
        <div
            className={"bodyS"}
            style={{
                color: "--primary6",
                display: "flex",
                flexDirection: "column",
                gap: "4px",
                alignContent: "flex-start",
                justifyContent: "left",
                alignItems: "flex-start",
                textAlign: "left"
        }}>
            <span className={"bodyLBold"} style={{ textAlign: "left" }}>{props.bot.title}</span>
            {props.bot.descriptopn}
        </div>
        <div style={{ margin: "auto" }}></div>
        <div style={{
            display: "flex",
            flexDirection: "column",
            alignContent: "flex-end",
            justifyContent: "right",
            alignItems: "flex-end",
            gap: "4px",
        }}>
            { props.bot.tags.map(TaggedBullet) }
        </div>
    </div>
}

function TaggedBullet(tag : Tag) {
    return <Bullet text={tag.name} color={tag.color} backgroundColor={tag.backgroundColor} textStyle={"bodySBold"}></Bullet>;
}

function Bullet(props: {text : string, color : string, backgroundColor : string, textStyle : string }) {
    return <div style={{
        display: "flex",
        borderRadius: "32px",
        gap: "10px",
        backgroundColor: props.backgroundColor,
        color: props.color,
        height: "22px",
        padding: "0px 8px 0px 8px",
        whiteSpace: "nowrap"
    }} className={"center_items " + props.textStyle}>{props.text}</div>;
}