import React, {JSX, useEffect, useState} from 'react';
import '../App.css';
import { BackButtonController } from '../BackButton';
import { Api, Mode, Preset } from '../Api';
import { useLazyEffect } from '../Effects';
import { EdipPromtPopup } from './EditPromtPopup';
import { motion } from "framer-motion"

export function SettingScreen(props : { api : Api, children : (openSettings : () => void) => any, version : number, incrementVersion : () => void }) {
    let [opened, setOpened] = useState(false);
    let modes = useLazyEffect(props.api.presets, props.version)
    console.log(modes)
    return <div style={{
        width: "100%",
        height: "100%",
        display: "flex",
    }}>
        { modes != null && <TariffScreen api={props.api} opened={opened} modes={modes.filter((e) => e.mode.id != "3c60dd6c-1f01-4904-b71f-93d9b2abc337")} version={props.version} incrementVersion={props.incrementVersion} close={() => setOpened(false)}></TariffScreen>  }
        <div style={{
            width: "100%",
            height: "100%",
            display: !opened ? "flex" : "none",
        }}>
            {props.children(() => setOpened(true))}
        </div>
    </div>
}

export function SashaGptScreen(props : { api : Api, version : number, incrementVersion : () => void}) {
    let modes = useLazyEffect(props.api.presets, props.version)
    let [mood , setMood] = useState<Moods>('Серьёзный')
    let [creativity , setCreativity] = useState<number>(0)
    let [length , setLength] = useState<AnswerLengths>("Коротко");
    let [mode , setMode] = useState<string>("");
    let [modeObject , setModeObject] = useState<Mode>();

    useEffect(() => {
        props.api.promt().then(data => {
          setMode(data.mode.id)
        });
    }, []);

    console.log(modes, 'modes');

    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            gap: "24px",
            padding: "16px",
            backgroundColor: "var(--primary1)",
            width: '95%'
        }}>
            <Promt api={props.api} version={props.version} incrementVersion={props.incrementVersion}></Promt>
            <Mood mood={mood} setMood={setMood}></Mood>
            <Creativity default={creativity} updateCreativity={setCreativity}></Creativity>
            <AnswerLength current={length} setCurrent={setLength}></AnswerLength>
            { modes != null && <ModeSelection api={props.api} current={mode} setCurrent={setMode} modex={modes.filter((e) => e.mode.id != "3c60dd6c-1f01-4904-b71f-93d9b2abc337")}/> }
        </div>
    );
}

export function TariffScreen(props : { api : Api, opened : boolean, close : () => void, modes : Preset[], version : number, incrementVersion : () => void}) {
    let [mood , setMood] = useState<Moods>('Серьёзный')
    let [creativity , setCreativity] = useState<number>(0)
    let [length , setLength] = useState<AnswerLengths>("Коротко");
    let [mode , setMode] = useState<string>("");
    let controller = new BackButtonController()
    controller.OnClick(() => props.opened ? props.close() : {})
    useEffect(() => {
        if (props.opened) {
            controller.MakeVisible()
        } else {
            controller.MakeInvisible()
        }
    }, [props.opened])
    return (
        <div style={{
            display: props.opened ? "flex" : "none",
            flexDirection: "column",
            gap: "24px",
            padding: "16px",
            backgroundColor: "var(--primary1)"
        }}>
            <Promt api={props.api} version={props.version} incrementVersion={props.incrementVersion}></Promt>
            <Mood mood={mood} setMood={setMood}></Mood>
            <Creativity default={creativity} updateCreativity={setCreativity}></Creativity>
            <AnswerLength current={length} setCurrent={setLength}></AnswerLength>
            <ModeSelection api={props.api} current={mode} setCurrent={setMode} modex={props.modes}></ModeSelection>
        </div>
    );
}

function ToWait(props: {children: any}) {
    return <div style={{
        display: "flex",
        flexDirection: "column",
        gap: "8px",
        textAlign: "center"
    }}>
        <span className={"bodyL"} style={{ color: "var(--primary4)" }}>Скоро будет!</span>
        <div style={{ filter: "blur(4px)"}}>{props.children}</div>
    </div> 
}

function GreenButton() : JSX.Element {
    return (<div style={{
        display: "flex",
        flexDirection: "column",
        gap: "24px",
        height: "50px",
        backgroundColor: "var(--accept1)",
        color: "var(--primary0)",
        borderRadius: "12px",
        borderColor: "transparent"
    }} className={"bodyLBold center_items"}>
        Выбрать тариф
    </div>);
}

function Promt(props : {api : Api, version : number, incrementVersion : () => void}) : JSX.Element {
    let [opened, setOpened] = useState(false);
    let [currentText, setCurrentText] =  useState('');

    useEffect(() => {
       let getData = () => {
           props.api.promt().then(data => {
               data.text && setCurrentText(data.text)
               //   data.mode &&
           });
       }
       getData();
        setInterval(() => {
           getData()
        }, 2000);
    }, []);

    return (<div style={{
        display: "flex",
        flexDirection: "row",
        gap: "8px",
        padding: "16px",
        backgroundColor: "var(--primary0)",
        color: "var(--primary4)",
        borderRadius: "12px",
        borderColor: "transparent",
        textAlign: "left",
        justifyContent: "center",
        lineHeight: "16.8px",
    }} className={"bodyS"} onClick={() => {
        setOpened(true)
        console.log("set opened true")
    }}>
        <span className={"bodyMBold"} style={{ color: "var(--primary5)" }}>Промт:  {
            
        // @ts-ignore
         /*window.Telegram.WebApp.initDataUnsafe.user.id */
         }</span>
        {currentText.length > 0 ? <motion.div
            initial={{ scale: 0.2 }}
            animate={{ scale: 1 }}
            transition={{ duration: 0.3 }}
        >{currentText.slice(0,250)}{currentText.length >= 250 && '...'}</motion.div> : 'Опишите, как должен вести себя бот по отношению к вам: манера общения, интонация и другие требования'
        }
        <EdipPromtPopup api={props.api} disabled={!opened} close={() => { setOpened(false); props.incrementVersion();} } version={props.version} incrementVersion={props.incrementVersion}></EdipPromtPopup>
    </div>);
}

type Moods = "Серьёзный" | "Милый" | "Обычный"

function Mood(props : { mood : Moods, setMood : (newMood : Moods) => void }) : JSX.Element {
    return (<div
        style={{
            display: "flex",
            flexDirection: "column",
            color: "var(--primary4)",
            textAlign: "left"
        }}>
        <span className={"bodyL"} style={{padding: "5px 16px 5px 16px"}}>Режим работы</span>
        <Selector variants={["Серьёзный", "Милый", "Обычный"]} label={(x) => x} selected={props.mood} setSelected={props.setMood}></Selector>
    </div>);
}

function Selector<T>(props : { variants : T[], label : (variant : T) => string, selected : T, setSelected : (newSelected : T) => void }) : JSX.Element {
    let body = props.variants.map((value) => SelectorElement(props.label(value), props.label(value), value === props.selected, () => props.setSelected(value)))

    return (<div style={{
        padding: "2px 3px 2px 3px",
        borderRadius: "8.91px",
        display: "flex",
        backgroundColor: "var(--primary2)"
    }}>
        {body}
    </div>)
}

function SelectorElement(text : string, key : string, selected : boolean, onClick : () => void) : JSX.Element {
    let font : string = ""
    let active_style = ""
    if (selected) {
        font = "bodySBold";
        active_style = "selection_box_active";
    } else {
        font = "bodyS";
    }

    return (<button
        style={{
            width: "100%"
        }}
        key={key}
        className={"selection_box center_items " + font + " " + active_style}
        onClick={(e) => onClick()}
    >
        {text}
    </button>)
}

function Creativity(props : { default : number, updateCreativity : (newMood : number) => void }) : JSX.Element {
    return (<div
        style={{
            display: "flex",
            flexDirection: "column",
            color: "var(--primary4)",
            textAlign: "left",
        }}>
        <span className={"bodyL"} style={{padding: "5px 16px 5px 16px"}}>Степень креативности</span>
        <div style={{
                backgroundColor: "var(--primary0)",
                borderRadius: "10px",
                height: "44px",
                padding: "0 16px",
                display: "flex"
        }} className={"center_items"}>
            <input
                type="range"
                id="creativity"
                name="creativity"
                min="0" max="100"
                style={{ width: "100%" }}
                value={props.default}
                onChange={(e) => props.updateCreativity(Number(e.target.value))}
            />
        </div>
    </div>);
}

type AnswerLengths = "Коротко" | "Обычно" | "Длинно"
function AnswerLength(props : { current : AnswerLengths, setCurrent : (newCurrent : AnswerLengths) => void }) : JSX.Element {
    return (<div
        style={{
            display: "flex",
            flexDirection: "column",
            color: "var(--primary4)",
            textAlign: "left"
        }}>
        <span className={"bodyL"} style={{padding: "5px 16px 5px 16px"}}>Режим работы</span>
        <Selector variants={["Коротко", "Обычно", "Длинно"]} label={(x) => x} selected={props.current} setSelected={props.setCurrent}></Selector>
    </div>);
}

function addSeparators(l : JSX.Element[]) : JSX.Element[] {
    if (l.length <= 1)
        return l;
    else {
        let res = [l[0]]
        for (let i = 1; i < l.length; ++i) {
            res.push(<Separator></Separator>)
            res.push(l[i])
        }
        return res;
    }
}

function ModeSelection(props : { api : Api, modex : Preset[], current : string, setCurrent : (newCurrent : string) => void }) : JSX.Element {
    let [currentMode, setCurrentMode] = useState<Mode>();
    useEffect(() => {
        localStorage.mode && setCurrentMode(JSON.parse(localStorage.mode))
    }, []);
    useEffect(() => {
        console.log(props.modex, 'modex');
    }, [props.modex]);

    useEffect(() => {
        console.log(props.current, 'CURRENT');
    }, [props.current]);

    return (<div
        style={{
            display: "flex",
            flexDirection: "column",
            color: "var(--primary4)",
            textAlign: "left",
        }}>
            <span className={"bodyL"} style={{padding: "5px 16px 5px 16px"}}>Выбрать режим</span>
            
            <div style={{
                    display: "flex",
                    flexDirection: "column",
                    backgroundColor: "var(--primary0)",
                    borderRadius: "10px",
                    borderColor: "transparent",
                }} className={"bodyS"}
            >
                {
                    addSeparators(props.modex.map((mode) => {
                        return <motion.div
                            initial={{ opacity: 0, scale: 0.5 }}
                            animate={{ opacity: 1, scale: 1 }}
                            transition={{ duration: 0.3 }}
                        ><ModeEntry name={mode.mode.title} text={mode.mode.description} selected={(currentMode && currentMode.id) === mode.id} clicked={() => {props.setCurrent(mode.id); // @ts-ignore
                            setCurrentMode(mode); props.api.updatePromt({ text: mode.text, mode: mode.mode, id: mode.mode.id }); localStorage.setItem('mode', JSON.stringify(mode))}}></ModeEntry></motion.div>

                    }))
                }
            </div>
    </div>);
}


function ModeEntry(props : { name : string, text : string, selected : boolean, clicked : () => void }) : JSX.Element {
    return (<div style={{
        display: "flex",
        flexDirection: "row",
        padding: "16px",
        color: "var(--primary5)",
        textAlign: "left",
        width: "100%",
        boxSizing: "border-box"
    }} className={"bodyM entry"} onClick={() => props.clicked()}>
        <div style={{
            display: "flex",
            flexDirection: "column",
            maxHeight: "70px",
            textOverflow: "ellipsis",
            overflow: "clip"
        }}>
            <span className={"bodyL"} style={{ color: "var(--primary6)" }}>{props.name}</span>
            {props.text}
        </div>
        <div style={{ margin: "auto" }}></div>
        { props.selected && 
            <div style={{ width: "16px", height: "16px", display: "flex", flexDirection: "row-reverse" }} className='center_items'><img src={"assets/TickIcon.svg"}/></div> }
    </div>);
}

function Separator() {
    return (<div style={{
        backgroundColor: "var(--primary3)",
        height: "0.33px",
        width: "342px",
        color: "transparent",
        marginLeft: "16px"
    }}>
        123
    </div>)
}

export default TariffScreen;
