import React, {JSX, useEffect, useState} from "react";
import {Api, Note, SashaEvent} from "../Api";
import {Notes, SashaEventWidget} from "../screen/CalendarScreen";

export function SearchBox(props : { api : Api, children : (active : boolean) => any}) : JSX.Element {
    let [query, setQuery] = useState('')
    let [notes, setNotes] = useState<Note[] | null>(null)
    let [tasks, setTasks] = useState<SashaEvent[] | null>(null)
    let [version, setVersion] = useState(0)

    useEffect(() => {
        (async () => {
            let { notes, events } = await props.api.search(query);
            setNotes(notes)
            setTasks(events)
            console.log(version)
        })()
    }, [props.api, query, version])

    return <div style={{
        paddingTop: "32px",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
        alignItems: "center",
        borderColor: "transparent",
        backgroundColor: "transparent",
    }}>
        <SearchLine value={query} changed={setQuery}/>
        {props.children(query === '')}
        <div style={{
            display: (query === '') ? "none" : "flex",
            flexDirection: "column",
            borderColor: "transparent",
            width: '100%',
        }}>
            { tasks != null && events(tasks, props.api, () => setVersion(version + 1)) }
            { notes != null &&
                <div style={{
                    padding: "16px",
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: notes.length < 1 ? 'column' : "initial",
                }}>
                    {notes.length > 0 ?  <Notes notes={notes} api={props.api} updateEventsNotes={() => setVersion(version + 1)}
                                                 compress={false}/> : <div style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "12px",
                        alignItems: 'center',
                    }}>
                        <div className={"notes_text bodyLBold"}>Не найдено</div>
                    </div>}
                </div>
            }
        </div>
    </div>
}

function events(events: SashaEvent[], api: Api, updateEventsNotes: () => void) {
    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            gap: "8px",
            alignItems: "left",
            justifyContent: "left",
            width: "100%",
            height: "100%",
            padding: "16px",    
            boxSizing: "border-box"
        }}>
            {events.map((value) => <SashaEventWidget api={api} key={value.id} task={value} active={false} updateEventsNotes={updateEventsNotes}></SashaEventWidget>)}
        </div>
    );
}

export function SearchLine(props : {value : string, changed :  React.Dispatch<React.SetStateAction<string>>}) : JSX.Element {
    return (
        <div style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            alignContent: "center",
            justifyContent: "center"
        }}>
            <div style={{
                marginLeft: "16px",
                marginRight: "16px",
                marginBottom: "16px",
                backgroundColor: "#E9EEF1",
                padding: "7px 8px 7px 8px",
                borderRadius: "10px",
                borderColor: "transparent",
                minHeight: "22px",
                maxHeight: "22px",
                width: "342px",
                display: "flex",
                flexDirection: "row"
            }}>
                <img src={"assets/search.svg"} style={{
                    width: "25px",
                    height: "22px"
                }} alt={"pencil icon"}/>
                <input
                    className={"input_field"}
                    style={{
                        borderColor: "transparent",
                        backgroundColor: "transparent",
                        fontFamily: "Inter",
                        fontSize: "16px",
                        fontWeight: "400",
                        lineHeight: "22px",
                        textAlign: "left",
                        color: "var(--primary4)",
                        width: "100%"
                    }}
                    type = "text"
                    placeholder={"Поиск"}
                    value={props.value}
                    onChange={ (event) => props.changed(event.target.value) }
                />
            </div>
        </div>
    );
}