import React, {JSX, useState} from 'react';
import '../App.css';
import  {useLazyEffect, useDate, useEvents} from "../Effects"
import {Api, Note, NoteBody, SashaEvent} from "../Api";
import {SearchBox} from "../components/SearchBox";
import {CreateNotePopup} from "./CreateNotePopup";
import TariffScreen, { SettingScreen } from './SettingsScreen';
import { fixTimezone, toLocalISOString } from '../time';
const PlusIcon = "assets/plus_icon.svg"
const PencilIcon = "assets/pencil_icon.svg"
const GearIcon = "assets/gear.svg"

function SashaGptScreenOld(props : { api : Api, version : number, updateVersion : () => void}) {
    const notes = useLazyEffect<Note[] | null>(props.api.notes, props.version)
    const date = useDate();
    let [selectedDate, updateSelectedDate] = useState(date);
    const tasks = useEvents(props.api, selectedDate, props.version);
    const updateEventsNotes = props.updateVersion

    return (
        <SettingScreen api={props.api} version={props.version} incrementVersion={props.updateVersion}>
            { (openSettings) => 
                <SearchBox api={props.api}>
                    { (active) =>
                        <div style={{
                            width: "358px",
                            display: active ? "flex" : "none",
                            flexDirection: "column",
                            alignContent: "center",
                            alignItems: "center",
                            gap: "24px",
                        }}>
                            <Organiser api={props.api} today={date} activeDate={selectedDate} updateActiveDate={updateSelectedDate} updateEventsNotes={updateEventsNotes} openSettings={openSettings} mothly={false}/>
                            { tasks != null && <TasksForDay api={props.api} day={selectedDate} tasks={tasks} updateEventsNotes={updateEventsNotes}></TasksForDay> }
                            { notes != null && <Notes api={props.api} updateEventsNotes={updateEventsNotes} notes={notes} compress={true}></Notes> }
                        </div>
                    }
                </SearchBox>
            }
        </SettingScreen>

    );
}

export function CalendarScreen(props : {api : Api, version : number, updateVersion : () => void}) {
    const date = useDate();
    let [selectedDate, updateSelectedDate] = useState(date);
    const tasks = useEvents(props.api, selectedDate, props.version);
    return (
        <SearchBox api={props.api}>
            { (active) =>
                <div style={{
                    width: "358px",
                    display: active ? "flex" : "none",
                    flexDirection: "column",
                    alignContent: "center",
                    alignItems: "center",
                    gap: "24px",
                }}>
                    <Organiser api={props.api} today={date} activeDate={selectedDate} updateActiveDate={updateSelectedDate} updateEventsNotes={props.updateVersion} openSettings={null} mothly={true}></Organiser>
                    { tasks != null && <TasksForDay api={props.api} day={selectedDate} tasks={tasks} updateEventsNotes={props.updateVersion}></TasksForDay> }
                </div>
            }
        </SearchBox>
    );
}

export function NotesScreen(props : { api : Api, version : number, updateVersion : () => void }) {
    const notes = useLazyEffect<Note[] | null>(props.api.notes, props.version);
    return <SearchBox api={props.api}>
        {(active) =>
            (<div style={{
              /*  width: "358px",*/
                display: active ? "flex" : "none",
                flexDirection: "column",
                alignContent: "center",
                alignItems: "center",
                width: '100%',
            }}>
                <NoteManager api={props.api} updateEventsNotes={props.updateVersion}/>
                { notes != null && <Notes api={props.api} updateEventsNotes={props.updateVersion}  notes={notes} compress={false}></Notes> }
            </div>)
        }
    </SearchBox>;
}

function Organiser(props : { api : Api, today : Date, activeDate : Date, updateActiveDate : (newDate : Date) => void, mothly : boolean, updateEventsNotes : () => void, openSettings : null | (() => void) }) : JSX.Element {
    return (
        <div style={{
            gap: "12px",
            display: "flex",
            flexDirection: "column"
        }}>
            <div style={{
                gap: "24px",
                display: "flex",
                flexDirection: "column"
            }}>
                <div style={{
                    gap: "16px",
                    display: "flex",
                    flexDirection: "column"
                }}>
                    <CurrentDate today={props.today}></CurrentDate>
                    <TaskManager api={props.api} today={props.today} updateEventsNotes={props.updateEventsNotes}/>
                </div>
                {
                    props.mothly ?
                        <Calendar setActive={props.updateActiveDate} active={props.activeDate} today={props.today}/> :
                        <CurrentWeekDateSelection today={props.today} active={props.activeDate} setActive={props.updateActiveDate}/>
                }

            </div>
            { props.openSettings != null && <SettingsButton onclick={props.openSettings}></SettingsButton> }
        </div>
    );
}

function SettingsButton(props : {onclick : (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => void}) {
    return (
        <button className={"settings_button bodyLBold"} onClick={ (e) => props.onclick(e) }>
            <img src={GearIcon} style={{opacity: "0.8"}} alt={"gear_icon"}/>
            Параметры
        </button>
    );
}

function copyDate(date : Date) : Date {
    return new Date(date.valueOf());
}

function weekOf(date : Date) {
    let res = []
    for (let i = date.getDate() - date.getDay() + 1; i <= date.getDate() + (7 - date.getDay()); i++) {
        let cur = copyDate(date)
        cur.setDate(i);
        res.push(cur);
    }
    return res;
}

function dateEquals(fisrt : Date, second : Date) {
    return fisrt.getDate() === second.getDate() && fisrt.getFullYear() === second.getFullYear() && fisrt.getMonth() === second.getMonth();
}

function CurrentWeekDateSelection(props : { today : Date, active : Date, setActive : (newSelectedDate : Date) => void }) : JSX.Element {
    return (<WeekWidget days={ weekOf(props.today)} setActive={props.setActive} active={props.active} today={props.today}></WeekWidget>);
}

function Calendar(props : { setActive : (date : Date) => void, active : Date, today : Date }) {
    let bestDays = (() => {
        let bestDays = [copyDate(props.active), copyDate(props.active), copyDate(props.active), copyDate(props.active), copyDate(props.active)]
        // TODO добавлять -1 и 6 недели в случае, если 1 - понеделник или 31 - воскревенье
        for (let i = 0; i < bestDays.length; i++) {
            bestDays[i].setDate(1 + i * 7);
        }
        return bestDays;
    })()


    let weeks = bestDays.map((day, index) =>
        (<WeekWidget key={index} days={weekOf(day)} setActive={props.setActive} active={props.active} today={props.today}/>)
    )
    return <div style={{
        display: "flex",
        flexDirection: "column",
        gap: "4px"
    }}>
        {weeks}
    </div>
}

function WeekWidget(props : { days : Date[], setActive : (date : Date) => void, active : Date, today : Date }) {
    let updateCount = (date : Date) => () => {
        props.setActive(date)
    }
    let typeFor = (date : Date): DateType => {
         if (dateEquals(date, props.active))
            return "active";
        else if (dateEquals(date, props.today))
            return "today";
        else if (date.getMonth() !== props.active.getMonth())
            return "disabled"
        else
            return "common";
    }
    const week = props.days;
    return (
        <div style={{
            display: "flex",
            flexDirection: "row",
            gap: "4px"
        }}>
            <DateBox date={week[0]} weekday={"ПН"} typee={typeFor(week[0])} clicked={updateCount(week[0])}></DateBox>
            <DateBox date={week[1]} weekday={"ВТ"} typee={typeFor(week[1])} clicked={updateCount(week[1])}></DateBox>
            <DateBox date={week[2]} weekday={"СР"} typee={typeFor(week[2])} clicked={updateCount(week[2])}></DateBox>
            <DateBox date={week[3]} weekday={"ЧТ"} typee={typeFor(week[3])} clicked={updateCount(week[3])}></DateBox>
            <DateBox date={week[4]} weekday={"ПТ"} typee={typeFor(week[4])} clicked={updateCount(week[4])}></DateBox>
            <DateBox date={week[5]} weekday={"СБ"} typee={typeFor(week[5])} clicked={updateCount(week[5])}></DateBox>
            <DateBox date={week[6]} weekday={"ВС"} typee={typeFor(week[6])} clicked={updateCount(week[6])}></DateBox>
        </div>
    );
}

function CurrentDate(props : {today : Date}) : JSX.Element {
    const months = ["января", "февраля", "марта", "апреля", "мая", "июня", "июля", "августа", "сентября", "октября", "ноября", "декабря"]
    const weekDays = ["Воскресенье", "Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота"]
    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            gap: "8px",
        }}>
            <span className={"current_date heading4"}>{weekDays[props.today.getDay()]}, {props.today.getDate()} {months[props.today.getMonth()]}</span>
            <span className={"current_date_today bodyL"}>Сегодня</span>
        </div>
    );
}

function NoteManager(props : {api : Api, updateEventsNotes : () => void}) {
    let [activeCreationTab, setAcriveCreationTab] = useState<null | "Note">(null);
    return (
        <div style={{
            display: "flex",
            flexDirection: "row",
            gap: "16px",
            width: "100%",
            boxSizing: "border-box",
            padding: "0 16px"
        }}>
            <div className={"light_blue_box new_task_note bodySBold"} onClick={() => setAcriveCreationTab("Note")}>
                <img src={PencilIcon} alt={"plus icon"}/>
                Новая заметка
            </div>

            <CreateNotePopup
                api={props.api}
                id={null}
                body= {{ title: "", text: "" }}
                disabled={activeCreationTab !== "Note"}
                close={() => { setAcriveCreationTab(null); props.updateEventsNotes()}}
            />
        </div>
    );
}

function TaskManager(props : {api : Api, today : Date, updateEventsNotes : () => void}) : JSX.Element {
    let [activeCreationTab, setAcriveCreationTab] = useState<null | "Note" | "Task">(null);
    return (
        <div style={{
            display: "flex",
            flexDirection: "row",
            gap: "16px",
            alignItems: "center",
            alignContent: "center",
            justifyContent: "center"
        }}>
            <div className={"blue_box new_task_note bodySBold"} onClick={() => setAcriveCreationTab("Task")}>
                <img src={PlusIcon} alt={"plus icon"}/>
                Новая задача
            </div>
            <div className={"light_blue_box new_task_note bodySBold"} onClick={() => setAcriveCreationTab("Note")}>
                <img src={PencilIcon} alt={"plus icon"}/>
                Новая заметка
            </div>

            <CreateNotePopup
                api={props.api}
                id={null}
                body= {{ title: "", text: "" }}
                disabled={activeCreationTab !== "Note"}
                close={() => { props.updateEventsNotes(); setAcriveCreationTab(null);}}
            />
            <CreateNotePopup
                api={props.api}
                id={null}
                body= {{ title: "", text: "", time: new Date(Date.now()) }}
                disabled={activeCreationTab !== "Task"}
                close={() => { props.updateEventsNotes(); setAcriveCreationTab(null);}}
            />
        </div>
    );
}
type Weekday = "ПН" | "ВТ" | "СР" | "ЧТ" | "ПТ" | "СБ" | "ВС"
type DateType = "common" | "today" | "active" | "disabled"

function DateBox(props: { date : Date, weekday : Weekday, typee : DateType, clicked : () => void }) : JSX.Element {
    return (<button className={"bodyMBold date_box " + props.typee + "_date_box"} onClick={() => props.clicked()}>
        {props.weekday}
        <span className={"bodySBold"}>{props.date.getDate()}</span>
    </button>)
}

const isDaysTheSame = (dayOne : Date) => (dayTwo : Date) : boolean => {
    return dayOne.getMonth() === dayTwo.getMonth() && dayOne.getFullYear() === dayTwo.getFullYear() &&  dayOne.getDate() === dayTwo.getDate();
}

export function TasksForDay(props: { api : Api, day : Date, tasks : SashaEvent[], updateEventsNotes : () => void}) : JSX.Element {
    const weekDays = ["Воскресенье", "Понедельник", "Вторник", "Среду", "Четверг", "Пятницу", "Субботу"]
    let tasks = props.tasks.filter((day) => isDaysTheSame(props.day)(day.time)).map((value, index, array) =>{
            let next : Date | null = null;
            if (index + 1 < array.length) {
                next = array[index + 1].time;
            }
            return <SashaEventWidget api={props.api} key={value.id} task={value} active={isTimeActive(value.time, next)} updateEventsNotes={props.updateEventsNotes}/>;
        }
    )

    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            alignItems: "left",
            justifyContent: "left",
            width: "100%"
        }}>
            <div className={"selected_day_text bodyLBold"}>Задачи на {weekDays[props.day.getDay()]}</div>
            <div style={{
                display: "flex",
                flexDirection: "column",
                gap: "8px"
            }}>{tasks}</div>
        </div>
    );
}

function isTimeActive(time : Date, next : Date | null) : boolean {
    const now : Date = new Date();
    if (time.getDate() != now.getDate() || time.getMonth() !== now.getMonth() || time.getFullYear() != now.getFullYear()) {
        return false;
    }
    if (next == null) {
        return time.getTime() < now.getTime();
    }
    return time.getTime() < now.getTime() && now.getTime() < next.getTime()
}

export function SashaEventWidget(props : { api : Api, task : SashaEvent, active : boolean, updateEventsNotes : () => void}) {
    let [activeCreationTab, setAcriveCreationTab] = useState<null | "edit">(null)
    let timeClass = "bodyMBold time";
    if (props.active) {
        timeClass = timeClass + " active_time";
    }
    return (
        <div style={{
            display: "flex",
            flexDirection: "row",
            gap: "6px"
        }} onClick={() => setAcriveCreationTab("edit")}>
            <div className={timeClass}>{toLocalISOString(fixTimezone(props.task.time)).slice(11, 16)}</div>
            <TaskBody api={props.api} id={props.task.id} body={props.task} updateEventsNotes={props.updateEventsNotes}></TaskBody>

            <CreateNotePopup
                api={props.api}
                id={props.task.id}
                body={props.task}
                disabled={activeCreationTab !== "edit"}
                close={() => { setAcriveCreationTab(null); props.updateEventsNotes()}}
            />
        </div>
    );
}


function TaskBody(props : { api : Api, id : string, body : NoteBody, updateEventsNotes : () => void }) {
    return (
        <div className={"task_box"} style={{
            display: "flex",
            flexDirection: "column",
            gap: "2px"
        }}>
            <span className={"task_label bodySBold"}>{props.body.title}</span>
            <span className={"task_text bodyS"}>{props.body.text}</span>
        </div>
    );
}

export function Notes(props : { notes : Note[], api : Api, compress : boolean, updateEventsNotes : () => void }) : JSX.Element {
    function color(index : number) : Color {
        let arr : Color[] = ["purple", "blue", "green",  "red"]
        return arr[index % 4];
    }

    let elements =
        props.compress ?
            groubBy(props.notes).map((value, index) => {
                let elements =
                        value.map((value, jindex) =>
                            <Box 
                                key={value.id} 
                                note={value} 
                                color={color(index * 2 + jindex)} 
                                square={jindex % 2 === index % 2}
                                api={props.api} 
                                updateEventsNotes={props.updateEventsNotes} />
                        )
                return <div key={index} style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "8px"
                }}>
                    {elements}
                </div>
            })
            : props.notes.map((value, jindex) => 
                <Box 
                    key={value.id}
                    note={value} 
                    color={color(jindex)} 
                    api={props.api} 
                    updateEventsNotes={props.updateEventsNotes}
                    square={false}/>)
    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            gap: "12px",
            width: '100%',
            padding: "16px",
            boxSizing: "border-box"
        }}>
            <div className={"notes_text bodyXLBold"}>Заметки</div>
            <span className='bodyM' style={{ display: (props.notes.length == 0) ? "flex" : "none" }}>Добавьте свою первую заметку</span>
            <div style={{
                display: "flex",
                flexDirection: "column",
                gap: "8px"
            }}>{elements}</div>
        </div>
    );
}

type Color =  "red" | "green" | "purple" | "blue"


function Box(props : { note : Note, api : Api, color : Color, square : boolean, updateEventsNotes : () => void }) {
    let [activeCreationTab, setAcriveCreationTab] = useState<null | "edit">(null)
    let classname = "colored_box"
    if (props.square) {
        classname += " quare_box"
    }
    if (props.color === "red") {
        classname += " red_box";
    } else if (props.color === "green") {
        classname += " green_box";
    } else if (props.color === "purple") {
        classname += " purple_box";
    } else if (props.color === "blue") {
        classname += " blue_box";
    }
    return (
        <div className={classname} onClick={() => setAcriveCreationTab("edit")}>
            {props.note.title}
            <CreateNotePopup
                api={props.api}
                id={props.note.id}
                body={props.note}
                disabled={activeCreationTab !== "edit"}
                close={() => { setAcriveCreationTab(null); props.updateEventsNotes()}}
                />
        </div>

    );


}

function groubBy<T>(arr: T[]) : T[][] {
    let res: T[][] = [[]]
    for (let i = 0; i < arr.length ; ++i) {
        if (res[res.length - 1].length === 2) {
            res.push([]);
        }
        res[res.length - 1].push(arr[i])
    }
    if (res[res.length - 1].length === 0) {
        res.pop();
    }
    return res;
}
