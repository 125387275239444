import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {RestApi} from "./RestApi";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

function user() {
    try {
        // @ts-ignore
        return window.Telegram.WebApp.initDataUnsafe.user.id;
    } catch (e) {
        return 0;
    }
}

console.log(new Date(Date.now()).getTimezoneOffset());

let api = new RestApi(user(), "")
root.render(<App api={api}/>);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
